import * as React from "react"
import type { HeadFC } from "gatsby"
import {navigate} from 'gatsby-link';

import Base from "../../layout/Base"

const Theme = () => {
  const goTo = (path: string) => {
    navigate(path);
  }

  return (
    <Base>
      <h1>Theme</h1>
      <p onClick={() => goTo('/theme/Colors')}>Colors</p>
      <p onClick={() => goTo('/theme/Fonts')}>Fonts</p>
    </Base>
  )
}

export default Theme

export const Head: HeadFC = () => <title>Theme</title>